.Renovacion-Form__Section {
  width: 100%;
  position: relative;
}

.Renovacion__Precio {
  width: 100%;
  padding: 0.5rem 50px;
  box-sizing: border-box;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
  text-align: center;
}
