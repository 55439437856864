@import url('https://fonts.googleapis.com/css?family=Poppins:200,400,400i,700');
@import url('https://fonts.googleapis.com/css?family=Merriweather&display=swap');

//Variables
$main-color: #E06F1D;
$secondary-color: #007BB5;
$text-color: #444;
$text-font: 'Poppins', sans-serif;
$title-font: 'Merriweather', serif;

//Config
body {
  margin: 0;
  font-family: $text-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  font-size: 16px;
  background-color: #ddd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-size: 16px;
}

h1 {
  color: $secondary-color;
  font-weight: bold;
  font-size: 1.8em;
}

h2 {
  color: $main-color;
  font-size: 1.5em;
}

h3 {
  font-size: 1.2em;
}

h4 {
  font-size: 1em;
}

h5 {
  font-size: 0.8em;
}

h3, h4, h5, h6 {
  color: $secondary-color;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $title-font;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.rg-text {
  width: 90%;
  max-width: 800px;
  margin: 10px auto;
  padding: 1.8em 50px;
}

.rg-card {
  width: 90%;
  max-width: 1024px;
  background-color: white;
  padding: 1.8em 50px;
  border-radius: 3px;
  margin: 10px auto;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
}

.rg-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%
}

.form-field {
  width: 100%;
  margin: 10px 0;
}

.desktop-half {
  width: 45%;
}

.form-field label {
  display: block;
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 105%;
}

.form-field input[type="text"],
.form-field input[type="email"],
.form-field input[type="password"],
.form-field input.rg-force-format {
  width: 100%;
  padding: 10px 0;
  margin-top: 5px;
  border: none;
  background: none;
  border-bottom: 2px solid $text-color;
  font-size: 1em;
  font-family: $text-font;
}

.form-field input[type="text"]:focus,
.form-field input[type="email"]:focus,
.form-field input[type="password"]:focus,
.form-field input.rg-force-format:focus {
  outline: none;
  border-bottom: 2px solid $main-color;
}

.form-field textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 2px solid $text-color;
  background: none;
  font-size: 1em;
  font-family: $text-font;
  box-sizing: border-box;
}

.form-field textarea:focus {
  outline: none;
  border: 2px solid $main-color;
}

.form-field select {
  width: 100%;
  padding: 10px 0;
  margin-top: 5px;
  border: none;
  background: none;
  border-bottom: 2px solid $text-color;
  height: 32pt;
  font-size: 1em;
}

.form-field select:focus {
  outline: none;
  border-bottom: 2px solid $main-color;
}

.form-title {
  width: 100%;
}

.form-sub-title {
  width: 100%;
}

.titulo-seccion {
  width: 90%;
  max-width: 1024px;
  margin: 20px auto;
}

@media only screen and (max-width: 686px) {
  .rg-card {
    padding: 1.3em 20px;
  }

  .desktop-half {
    width: 100%;
  }
}
