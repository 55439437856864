.PopUpMessage__Container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .PopUpMessage__Box {
    width: 50%;
    max-width: 800px;
    max-height: 90vh;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin: 0;
    }

    .PopUpMessage__Title{
      width: 100%;
      padding: 10px 20px;
      box-sizing: border-box;
      border-bottom: 1px solid #666;
      font-weight: bold;
    }

    .fa-times {
      float: right;

      &:hover {
        cursor: pointer;
      }
    }

    .PopUpMessage__Text {
      width: 100%;
      overflow-y: scroll;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 20px;
      box-sizing: border-box;
    }
  }

  @media only screen and (max-width: 686px) {

    .PopUpMessage__Box {
      width: 90%;
    }    
  }
}

.PopUpMessage__Hidden {
  display: none;
}