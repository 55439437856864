@import url(https://fonts.googleapis.com/css?family=Poppins:200,400,400i,700);
@import url(https://fonts.googleapis.com/css?family=Merriweather&display=swap);
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444;
  font-size: 16px;
  background-color: #ddd; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

p {
  font-size: 16px; }

h1 {
  color: #007BB5;
  font-weight: bold;
  font-size: 1.8em; }

h2 {
  color: #E06F1D;
  font-size: 1.5em; }

h3 {
  font-size: 1.2em; }

h4 {
  font-size: 1em; }

h5 {
  font-size: 0.8em; }

h3, h4, h5, h6 {
  color: #007BB5; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Merriweather", serif;
  text-transform: uppercase;
  letter-spacing: 2px; }

.rg-text {
  width: 90%;
  max-width: 800px;
  margin: 10px auto;
  padding: 1.8em 50px; }

.rg-card {
  width: 90%;
  max-width: 1024px;
  background-color: white;
  padding: 1.8em 50px;
  border-radius: 3px;
  margin: 10px auto;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15); }

.rg-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%; }

.form-field {
  width: 100%;
  margin: 10px 0; }

.desktop-half {
  width: 45%; }

.form-field label {
  display: block;
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 105%; }

.form-field input[type="text"],
.form-field input[type="email"],
.form-field input[type="password"],
.form-field input.rg-force-format {
  width: 100%;
  padding: 10px 0;
  margin-top: 5px;
  border: none;
  background: none;
  border-bottom: 2px solid #444;
  font-size: 1em;
  font-family: "Poppins", sans-serif; }

.form-field input[type="text"]:focus,
.form-field input[type="email"]:focus,
.form-field input[type="password"]:focus,
.form-field input.rg-force-format:focus {
  outline: none;
  border-bottom: 2px solid #E06F1D; }

.form-field textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 2px solid #444;
  background: none;
  font-size: 1em;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box; }

.form-field textarea:focus {
  outline: none;
  border: 2px solid #E06F1D; }

.form-field select {
  width: 100%;
  padding: 10px 0;
  margin-top: 5px;
  border: none;
  background: none;
  border-bottom: 2px solid #444;
  height: 32pt;
  font-size: 1em; }

.form-field select:focus {
  outline: none;
  border-bottom: 2px solid #E06F1D; }

.form-title {
  width: 100%; }

.form-sub-title {
  width: 100%; }

.titulo-seccion {
  width: 90%;
  max-width: 1024px;
  margin: 20px auto; }

@media only screen and (max-width: 686px) {
  .rg-card {
    padding: 1.3em 20px; }
  .desktop-half {
    width: 100%; } }

.New-Lead-Form__Section {
  width: 100%;
  position: relative; }

.New-Lead__Precio {
  width: 100%;
  padding: 0.5rem 50px;
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  text-align: center; }

.Button {
  display: block;
  width: auto;
  padding: 10px 15px;
  border: none;
  box-sizing: border-box;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 3px;
  margin: 5px 0; }
  .Button:hover {
    cursor: pointer; }

.Center {
  margin: 5px auto; }

.Big {
  font-size: 140%;
  padding: 10px 15px; }

.Small {
  font-size: 60%;
  padding: 5px 10px; }

.Button__Primary {
  background-color: #CB6015;
  color: #fff;
  border: 1px solid #CB6015; }
  .Button__Primary:hover {
    border: 1px solid #CB6015;
    background-color: #fff;
    color: #CB6015; }
  .Button__Primary:focus {
    outline: none; }

.Button__Secondary {
  background-color: #444;
  color: #fff;
  border: 1px solid #444; }
  .Button__Secondary:hover {
    border: 1px solid #444;
    background-color: #fff;
    color: #444; }

.Button__Danger {
  background-color: red;
  color: #fff;
  border: 1px solid red; }
  .Button__Danger:hover {
    border: 1px solid red;
    background-color: #fff;
    color: red; }

.PopUpMessage__Container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center; }
  .PopUpMessage__Container .PopUpMessage__Box {
    width: 50%;
    max-width: 800px;
    max-height: 90vh;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .PopUpMessage__Container .PopUpMessage__Box p {
      margin: 0; }
    .PopUpMessage__Container .PopUpMessage__Box .PopUpMessage__Title {
      width: 100%;
      padding: 10px 20px;
      box-sizing: border-box;
      border-bottom: 1px solid #666;
      font-weight: bold; }
    .PopUpMessage__Container .PopUpMessage__Box .fa-times {
      float: right; }
      .PopUpMessage__Container .PopUpMessage__Box .fa-times:hover {
        cursor: pointer; }
    .PopUpMessage__Container .PopUpMessage__Box .PopUpMessage__Text {
      width: 100%;
      overflow-y: scroll;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 20px;
      box-sizing: border-box; }
  @media only screen and (max-width: 686px) {
    .PopUpMessage__Container .PopUpMessage__Box {
      width: 90%; } }

.PopUpMessage__Hidden {
  display: none; }

.Renovacion-Form__Section {
  width: 100%;
  position: relative; }

.Renovacion__Precio {
  width: 100%;
  padding: 0.5rem 50px;
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  text-align: center; }

@charset "UTF-8";
.Not-Found__Section  {
  width: 100%; }

